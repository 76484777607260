import React, { useEffect, useState, useRef, useContext } from "react";
import {
  getSingleGameApi,
  getWithoutRunningGameApi,
  LikeApi,
  disLikeApi,
  TrackGame,
  TrackLike,
  TrackDisLike,
  TrackFullScreen,
} from "../../../APIs/UserAll_api";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import ROUTES from "../../../Configs/Routes";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import MainContext from "../../Context/MainContext";

const PlayGame = () => {
  const [game, setGame] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gameList, setGameList] = useState([]);
  const [like, setLike] = useState("");
  const [disLike, setDisLike] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const location = useLocation();

  const [dragging, setDragging] = useState(false);
  const [dragStartTime, setDragStartTime] = useState(null);
  const [hasMoved, setHasMoved] = useState(false);
  const data = useContext(MainContext);

  const { catId } = useParams();
  const ref = useRef(null);
  const navigate = useNavigate();

  const getAllGame = async () => {
    try {
      setLoading(true);
      const res = await getSingleGameApi(location.state.game_id);
      if (res.status === 200) {
        setLoading(false);
        setGame(res.data);
        setLike(res.data.likeCount);
        setDisLike(res.data.dislikeCount);
      } else if (res.response.status === 404) {
        setLoading(false);
        navigate(ROUTES.PAGE_NOT_FOUND);
      } else if (res.response.status === 500) {
        setLoading(false);
        navigate(ROUTES.PAGE_NOT_FOUND);
      } else {
        setLoading(false);
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const getWithoutRunningGame = async () => {
    try {
      const res = await getWithoutRunningGameApi(location.state.game_id);
      if (res.status === 200) {
        setGameList(res.data.data);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getAllGame();
    getWithoutRunningGame();
    let gameGrids = document.getElementsByClassName("showvideo");
    let gameGrid = document.getElementsByClassName("showwebgl");
    // setTimeout(() => {
    //   gameGrids[0]?.classList.add("d-none");
    //   gameGrid[0]?.classList.remove("d-none");
    // }, [6000]);
  }, [catId, fullScreen]);

  const handleHover = (e) => {
    e.target.play();
  };

  const handleHoverOut = (e) => {
    e.target.currentTime = 0;
    e.target.pause();
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  const handleBack = () => {
    navigate(ROUTES.USER);
  };

  const likeLog = async () => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game.data._id
      }
      await TrackLike(body)
    }
  }

  const handleLike = async () => {
    let passdata = {
      game_id: game.data._id,
    };
    try {
      const res = await LikeApi(passdata);
      if (res.status === 200) {
        likeLog()
        setLike(res.data.likeCount);
        setDisLike(res.data.dislikeCount);
      }
    } catch (error) {
    }
  };

  const dislikeLog = async () => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game.data._id
      }
      await TrackDisLike(body)
    }
  }

  const handleDislike = async () => {
    let passdata = {
      game_id: game.data._id,
    };
    try {
      const res = await disLikeApi(passdata);
      if (res.status === 200) {
        dislikeLog()
        setDisLike(res.data.dislikeCount);
        setLike(res.data.likecount);
      }
    } catch (error) {
    }
  };

  const elemRef = useRef(null);
  const handleFullScreen = async () => {
    const elem = elemRef.current;

    if (elem && elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem && elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem && elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  const handleExitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    }
  };

  const handleLog = async () => {
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: game.data._id
      }
      await TrackFullScreen(body)
    }
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
    });
    return () => {
      document.removeEventListener('fullscreenchange', () => {
      });
    };
  }, []);



  const handleDragStart = () => {
    setDragging(true);
    setDragStartTime(Date.now());
  };

  const handleDrag = () => {
    setHasMoved(true);
  };

  const handleDragStop = (e) => {
    setDragging(false);
    const dragDuration = Date.now() - dragStartTime;
    if (!hasMoved && dragDuration < 200) {
      handleBack(e);
    }
    setHasMoved(false);
    setDragStartTime(null);
  };

  useEffect(() => {
    if (width < 576) {
      handleFullScreen()
    }
  }, [])

  useEffect(() => {
    if (width <= 1024) {
      handleFullScreen()
    }
  }, [width])

  const handleGame = async (id, name) => {

    data.setGameName(name)
    const decodedParam = decodeURIComponent(name);
    const mergedParam = decodedParam.split(' ').join('').toLowerCase();

    navigate(`${ROUTES.PLAY_GAME.LIST}/${mergedParam}`, { state: { game_id: id } })
    if (localStorage.getItem("gameUserToken")) {
      const body = {
        game_id: id
      }
      await TrackGame(body)
    }
  }

  return (
    <div className="position-reletive">
      {width < 576 &&

        location.pathname === `${ROUTES.PLAY_GAME.LIST}/${catId}` ? (
        <Draggable
          axis="y"
          bounds="body"
          onStart={handleDragStart}
          onDrag={handleDrag}
          onStop={handleDragStop}
        >
          <button className="back-span position-absolute" ><i className="fa-solid fa-backward"></i></button>
        </Draggable>
      ) : (
        ""
      )}
      {fullScreen === false ? (
        <>
          {loading ? (
            <div className="text-center">
              <div
                class="spinner-border text-white"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <div className="play-one d-none d-md-block">
                <div className="playGamesDiv mt-2">
                  {gameList.slice(0, 12).map((game) => {
                    return (
                      <div onClick={() => handleGame(game?._id, game?.name)}>
                        <div className="gameGridDiv" key={game}>
                          {game.video_file && (
                            <video
                              onMouseEnter={(e) => handleHover(e)}
                              onMouseLeave={(e) => handleHoverOut(e)}
                              className="homeVideo"
                              src={game.video_file}
                              muted
                              loop
                              autoPlay
                              onClick={() => handleGame(game?._id, game?.name)}
                            ></video>
                          )}
                          <img
                            src={game.image}
                            alt="image"
                            className={game.video_file ? "homeimage" : ""}
                          />
                          <span className="gameName">{game.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="play-two">
                <div className="text-center">
                  <iframe
                    className="showwebgl iframe-main"
                    src={`${process.env.REACT_APP_AWS_URL}webgl_staging/${game?.data?.webgl_zip}/index.html`}
                    ref={elemRef}
                  ></iframe>
                </div>
                <div className="game-discription d-none d-sm-block">
                  <div className="mt-4 d-flex justify-content-between py-4 align-items-center">
                    <div className="text-start">
                      <p className="game-type text-white mb-0">Name</p>
                      <p className="game-name text-white mb-0">{game.data?.name}</p>
                    </div>
                    <div className="d-flex">
                      <p className="likeIcon me-4 d-flex align-items-center mb-0">
                        <i
                          className={
                            like
                              ? "fa-regular fa-thumbs-up me-2 like-icon"
                              : "fa-regular fa-thumbs-up me-2"
                          }
                          onClick={handleLike}
                        ></i>
                        <span>{like}</span>
                      </p>
                      <p className="likeIcon me-4 d-flex align-items-center mb-0">
                        <i
                          className={
                            disLike
                              ? "fa-regular fa-thumbs-down me-2 dislike-icon"
                              : "fa-regular fa-thumbs-down me-2"
                          }
                          onClick={handleDislike}
                        ></i>{" "}
                        <span>{disLike}</span>
                        <i
                          class="fa-solid fa-expand ms-4"
                          onClick={() => { handleFullScreen(); handleLog(); }}
                        ></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="play-one d-none d-xl-block">
                <div className="playGamesDiv mt-2">
                  {gameList.slice(12, 24).map((game) => {
                    return (
                      <div onClick={() => handleGame(game?._id, game?.name)}>
                        <div className="gameGridDiv" key={game}>
                          {game.video_file && (
                            <video
                              onMouseEnter={(e) => handleHover(e)}
                              onMouseLeave={(e) => handleHoverOut(e)}
                              className="homeVideo"
                              src={game.video_file}
                              muted
                              loop
                              onClick={() => handleGame(game?._id, game?.name)}
                            ></video>
                          )}
                          <img
                            src={game?.image}
                            alt="image"
                            className={game?.video_file ? "homeimage" : ""}
                          />
                          <span className="gameName">{game?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {loading ? <div className="text-center">
            <div
              class="spinner-border text-white"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div> : <div className="position-absolute full-screen-div" style={fullScreen ? { width: '100%', height: '100%' } : {}}>
            <iframe
              className="showwebgl frame-full-screen"
              src={`${process.env.REACT_APP_AWS_URL}webgl_staging/${game?.data?.webgl_zip}/index.html`}
              ref={elemRef}
            ></iframe>
            <i
              className="fa-solid fa-compress ms-4"
              onClick={handleExitFullScreen}
            ></i>
          </div>}
        </>
      )}
    </div>
  );
};

export default PlayGame;
